import React, { Fragment } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SiteLogo from '../sitelogo';
import './sitenavigation.css';
import { User, useAuth } from '../../../hooks/useAuth';
import { LinkContainer } from "react-router-bootstrap";

function SiteNavigation(props) {
  // Get auth state and re-render anytime it changes
  const auth = useAuth();
  const user = auth.getUser();

  const signout = () => {
    auth.signout();
  };

  const accountStyle = {
    color: "#ffffff",
    fontFamily: "Oswald,Arial,sans-serif"
  };

  return (
    <Navbar bg="dark" variant="dark" expand="md">
        <Container>
            <SiteLogo />
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  {/*<LinkContainer to="/features">
                    <Nav.Link>Features</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/news">
                    <Nav.Link>News</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/reviews">
                    <Nav.Link>Reviews</Nav.Link>
                  </LinkContainer>*/}
                  <LinkContainer to="/genres">
                    <Nav.Link>Genres</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/boards">
                    <Nav.Link>Boards</Nav.Link>
                  </LinkContainer>
                  {user && user.isWriter &&
                    <NavDropdown title="Author" id="author-dropdown">
                      <NavDropdown.Item href="/news/create">Create News</NavDropdown.Item>
                      <NavDropdown.Item href="/features/create">Create Feature</NavDropdown.Item>
                      <NavDropdown.Item href="/genres/create">Create Genre</NavDropdown.Item>
                      <NavDropdown.Item href="/reviews/create">Create Review</NavDropdown.Item>
                      <NavDropdown.Item href="/artists/create">Create Artist</NavDropdown.Item>
                      <NavDropdown.Item href="/albums/create">Create Album</NavDropdown.Item>
                    </NavDropdown>
                  }
                </Nav>
                <Form inline>
                  <Nav className="mr-auto">
                    {user ? (
                      <Fragment>
                        <Navbar.Text style={accountStyle}>{user.username} / </Navbar.Text>
                        <Nav.Link onClick={signout}>Sign Out</Nav.Link>
                      </Fragment>
                    ) : (
                      <Nav.Link href="/signin-signup">Signin / Signup</Nav.Link>
                    )}
                  </Nav>
                  <Button variant="link" onClick={props.handleSearchClick}>
                    <FontAwesomeIcon icon={faSearch} color="white" className="search-button" />
                  </Button>
                </Form>
            </Navbar.Collapse>
        </Container>
    </Navbar>
  );
}

export default SiteNavigation;