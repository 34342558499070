import React from 'react';
import { default as media } from 'react-bootstrap/Media';
import Styled from 'styled-components';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

const ArticleCard = Styled(media)`
  border-bottom: 1px solid #e6e6e6;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const ArticleCardBody = Styled(media.Body)`
  marginTop: auto;
  marginBottom: auto;
`;

const ArticleCardTitle = Styled.h5`
  color: #000000;

  ${ArticleCard}:hover & {
    color: #e4581d;
  }
`;

const ArticleCardImage = Styled.img`
  width: 120px;
  object-fit: cover;
  object-position: 50% 0;
  outline: 1px solid rgba(0, 0, 0, 0.1);
  outline-offset: -1px;

  ${ArticleCard}:hover & {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    transition: all 2s ease;
    -webkit-transition: all 2s ease;
  }
`;

const ArticleCardClock = Styled(FontAwesomeIcon)`
  display: 'inline-block'
`;

const ArticleCardType = Styled.h6`
  color: #e4581d;
  font-size: 0.875rem;
  font-weight: bold;
`;

const ArticleCardMetadata = Styled.div`
  color: gray;
`;

const ArticleLink = (type, id) => {
  let link = "";

  switch(type) {
      case "News":
        link = "/news/" + id;
        break;
      case "Blog":
        link = "/blogpost/" + id;
        break;
      case "Genre":
        link = "/genres/" + id;
        break;
      case "Review":
        link = "/reviews/" + id;
        break;
      default:
        // code block
    }

    return link;
}

const Article = (props) => {
  return (
    <Link to={ArticleLink(props.type, props.id)}>
      <ArticleCard key={props.id}>
        <ArticleCardBody>
          <ArticleCardType>{props.type}</ArticleCardType>
          <ArticleCardTitle>
            {props.title}
          </ArticleCardTitle>
          <ArticleCardMetadata><ArticleCardClock icon={faClock} /> <Moment fromNow>{props.createdDate}</Moment></ArticleCardMetadata>
        </ArticleCardBody>
        {props.url !== null &&
          <div className="media-left">
            <ArticleCardImage alt={props.title} src={props.url} />
          </div>
        }
      </ArticleCard>
    </Link>
  )
}

Article.defaultProps = {
  id: '00000000-0000-0000-0000-000000000000',
  title: 'New Review',
  type: 'Review',
  url: '',
  createdDate: ''
}

export default Article