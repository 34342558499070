import React, { useState, useEffect, Fragment } from 'react';
import LoadingSpinner from '../../components/loadingspinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import UsersGateway from '../../gateways/usersgateway';
import Media from 'react-bootstrap/Media';
import UserArticles from './userarticles';

function User(props) {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const usersGateway = UsersGateway();

  useEffect(() => {
    const fetchUser = async () => {
      const user = await usersGateway.get(props.match.params.id);
      setUser(user);
      setLoading(false);
    }

    fetchUser();
  }, []);

  return (
    loading ? (
      <LoadingSpinner />
    ) : (
      <Fragment>
        <Container>
          <Row>
            <Col>
              <Media key={props.id}>
                <div className="media-left mr-2">
                  <a href="/User/Alex-Rice">
                    <img 
                        width={64}
                        height={64}
                        className="mr-3"
                        alt={user.username} 
                        src={user.imageUrl} />
                  </a>
                </div>
                <Media.Body>
                  <h5 className="media-heading" id="media-heading">
                    {user.username} {user.staff ? '// Staff' : user.moderator ? '// Moderator' : ''}
                  </h5>
                  {user.biography}
                </Media.Body>
              </Media>
            </Col>
          </Row>
          <Row>
            <Col>
              <UserArticles userId={user.id} username={user.username} />
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  );
}    

export default User;