import React, { Fragment, useState, useEffect } from 'react';
import MediaList from '../medialist';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ImagesGateway from '../../gateways/imagesGateway';

function MediaLibrary(props) {
    const [imageUploaded, setImageUploaded] = useState('');
    const handleClose = () => props.setShow(false);
    const imagesGateway = ImagesGateway();

    const handleUploadImage = async (event) => {
        event.preventDefault();

        let image = event.target.files[0];

        let formdata = new FormData();
        formdata.append("image", image);

        let result = await imagesGateway.postArticleImages(props.imagePrefix, formdata)
            .then(setImageUploaded(Math.random));

        props.addImage('![Article Image](' + result + ')');
    }

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Media Center</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MediaList articleId={props.imagePrefix} refresh={imageUploaded} />
            </Modal.Body>
            <Modal.Footer>
                <Form.File
                    id="articleImage"
                    label="Upload Image"
                    custom
                    onChange={handleUploadImage}
                />
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MediaLibrary;