import { blogListUrl, blogUrl, userBlogListUrl } from './urls';
import { useAuth } from '../hooks/useAuth';

const BlogGateway = () => {
    const auth = useAuth();
    const jwt = auth.jwt;

    async function getAll() {
        return await fetch(blogListUrl)
            .then(toJson);
    }

    async function getAllByUser(id) {
        return await fetch(userBlogListUrl(id))
            .then(toJson);
    }

    async function get(id) {
        return await fetch(blogUrl(id))
            .then(toJson);
    }

    async function post(formdata) {
        var requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Authorization':  `bearer ${jwt}`
          }), 
          body: formdata,
          redirect: 'follow'
    };
    
    fetch(blogListUrl, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }

    function toJson(response){
        return response.json();
      }
      
    return Object.freeze({
        getAll,
        getAllByUser,
        get,
        post
    });
}

export default BlogGateway;