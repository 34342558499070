import React, { useState, useEffect } from 'react';
import Media from 'react-bootstrap/Media'
import FeaturesGateway from '../../gateways/featuresGateway';

function FeatureList() {
  const [features, setFeature] = useState([]);
  const gateway = FeaturesGateway();

  useEffect(() => {
    const fetchFeature = async () => {
      const latestFeatures = await gateway.getAll();
      setFeature(latestFeatures);
    }

    fetchFeature();
  }, []);

  return (
    <div>
      {features.map(feature => (
        <Media key={feature.id}>
          <Media.Body>
            <h5><a href={"/features/" + feature.id}>{feature.title}</a></h5>
            <p>{feature.summary}</p>
            <p>{feature.content}</p>
          </Media.Body>
        </Media>
      ))}
    </div>
  );
}

export default FeatureList;