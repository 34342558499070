import React from 'react';
import Container from 'react-bootstrap/Container';

var headerTextStyle = {
  fontFamily: 'Oswald',
  fontWeight: '400',
  fontSize: '2.875rem',
  lineHeight: '3.25rem',
  paddingTop: '2.6rem'
}

var subheaderTextStyle = {
  fontFamily: 'Oswald',
  fontWeight: '200',
  fontSize: '1.64286rem',
  lineHeight: '2.22857rem',
  marginBottom: 0
}

var articleHeaderContentStyle = {
  border: '0',
  marginBottom: '16px',
  paddingBottom: '16px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
}

const ArticleHeaderText = (props) => {
  return (
    <div id="article-header">
      <Container>
        <div id="article-header-content" style={articleHeaderContentStyle}>
          <h1 style={headerTextStyle}>{props.header}</h1>
          <h2 style={subheaderTextStyle}>{props.subheader}</h2>
        </div>
      </Container>
    </div>
  );
}

ArticleHeaderText.defaultProps = {
  header: "",
  subheader: ""
}

export default ArticleHeaderText;