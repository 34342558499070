import React, { useEffect, useState } from 'react';
import Card from '../../components/card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ArticlesGateway from '../../gateways/articlesgateway';
import LoadingSpinner from '../../components/loadingspinner';
import { truncateText } from '../../utils';

function UserArticles(props) {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const articlesGateway = ArticlesGateway();

  useEffect(() => {
    const fetchGenres = async () => {
      const articles = await articlesGateway.getByUser(props.userId);
      setArticles(articles);
      setLoading(false);
    }
    fetchGenres();
  }, []);

  console.log('Articles');
  console.log(articles);

  return (
    loading ? (
      <LoadingSpinner />
    ) : (
      <Container>
        <h3>Articles By {props.username}</h3>
        <Row xs={3} md={3} className="mb-4">
          {articles.item2.map(article => (
            <Col>
              <a href={"/articles/" + article.id}>
                <Card 
                  title={article.title}
                  content={truncateText(article.content,100)}
                  image={article.image}
                />
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    )
  );
}

export default UserArticles;