import React, { useState, useEffect } from 'react';
import ImageGateway from '../../gateways/imagesGateway';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingSpinner from '../loadingspinner';
import Image from 'react-bootstrap/Image';
 
function MediaList(props) {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const imageGateway = ImageGateway();

  useEffect(() => {
    fetchImages();
  }, [props.refresh]);

  const fetchImages = async () => {
    setLoading(true);
    const articleImages = await imageGateway.getArticleImages(props.articleId);
    setImages(articleImages);
    setLoading(false);
  }

  function handleSelectImage(e) {
    e.preventDefault();
    console.log('The image was clicked:' + e.target.src);
  }

  return (
    loading ? (
      <LoadingSpinner />
    ) : (
      <Container>
        <Row>
          {images.map(image => (
            <Col key={image}><a id={image} onClick={handleSelectImage}><Image src={image} thumbnail /></a></Col>
          ))}
        </Row>
      </Container>
    )
  )
}

export default MediaList;