import React from 'react';
import Moment from 'moment';

const FormatTime = (minutes, seconds) => {
  var duration = Moment.duration({"minutes": minutes});
  duration.add(seconds, 'seconds');
  return duration.format('hh:mm:ss');
}

const AlbumTracks = (props) => {
  return (
    <div id="tracks">
      {props.tracks &&
        <React.Fragment>
          <h4>Track List</h4>
          <ol>
            {props.tracks.map(track => (
              <li key={track.id} value={track.order}>{track.title} {FormatTime(track.time.minutes, track.time.seconds)}</li>
            ))}
          </ol>
        </React.Fragment>
      }
    </div>
  )
}

AlbumTracks.defaultProps = {
  url: "",
  tracks: []
};

export default AlbumTracks;