import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ArtistsGateway from '../../gateways/artistsGateway';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function CreateArtist() {
  const [id, setId] = useState(uuidv4());
  const [name, setName] = useState("");
  const [realName, setRealName] = useState("");
  const [profile, setProfile] = useState("");
  const [country, setCountry] = useState("");
  const [solo, setSolo] = useState(false);
  const [image, setImage] = useState({preview: '', raw: '', name: ''});

  const artistsGateway = ArtistsGateway();

  const handleChange = (event) => {   
    setImage({
      preview: URL.createObjectURL(event.target.files[0]),
      raw: event.target.files[0],
      name: event.target.files[0].name
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("name", name);
    formdata.append("realName", realName);
    formdata.append("image", image.raw);

    await artistsGateway.post(formdata);
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Control type="text" placeholder="Artist Name" name="name" onChange={e => setName(e.target.value)} value={name} />
        </Form.Group>
        <Form.Group controlId="formRealName">
          <Form.Control type="text" placeholder="Artist Real Name" name="realName" onChange={e => setRealName(e.target.value)} value={realName} />
        </Form.Group>
        <Form.File
          id="artistImage"
          label={image.name}
          custom
          onChange={handleChange}
        />
        <Button variant="primary" type="submit">Submit</Button>
      </Form>
    </Container>
  );
}

export default CreateArtist;