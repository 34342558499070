import React, { useState, useEffect } from 'react';
import PostGateway from '../../gateways/postsGateway';
import Post from '../post';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function BoardList(props) {
  const [posts, setPosts] = useState([]);
  const gateway = PostGateway();

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    const latestPosts = await gateway.getAll(props.boardId);
    setPosts(latestPosts);
  }

  return (
    <Row>
      {posts.map(post => (
        <Col key={post.id} md={{ span: 6, offset: 3 }}>
          <Post fetchPosts={fetchPosts} authorId={post.author.id} authorName={post.author.username} authorImage={post.author.imageUrl} content={post.content} postId={post.id} createdDate={post.createdDate} staff={post.author.staff} moderation={post.author.moderator} boardId={props.boardId} />
        </Col>
      ))}
    </Row>
  );
}

export default BoardList;