import React from 'react';

function ReviewList() {
  return (
    <div className="App">
      <div className="container">
        <p>Reviews</p>
      </div>
    </div>
  );
}

export default ReviewList;