import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AlbumsGateway from '../../gateways/albumsGateway';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormList from './formlist';

function CreateAlbum() {
  const [id] = useState(uuidv4());
  const [name, setName] = useState("");
  const [releaseDay, setReleaseDay] = useState("");
  const [releaseMonth, setReleaseMonth] = useState("");
  const [releaseYear, setReleaseYear] = useState("");
  const [image, setImage] = useState({preview: '', raw: '', name: ''});
  const [artists, setArtists] = useState([]);
  const [artist, setArtist] = useState({id: '', name: ''});

  const albumsGateway = AlbumsGateway();

  const handleChange = (event) => {   
    setImage({
      preview: URL.createObjectURL(event.target.files[0]),
      raw: event.target.files[0],
      name: event.target.files[0].name
    })
  }

  const handleAddArtist = () => {
    if(!artists.includes(artist)) {
      setArtists([...artists, artist]);
    }
  }

  const handleRemoveArtist = (name) => {
    setArtists(artists.filter(el => el !== name));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("name", name);
    formdata.append("image", image.raw);
    formdata.append("releaseDay", releaseDay);
    formdata.append("releaseMonth", releaseMonth);
    formdata.append("releaseYear", releaseYear);

    for (let i = 0; i < artists.length; i++) {
      for (let key of Object.keys(artists[i])) {
        formdata.append(`artists[${i}][${key}]`, artists[i][key]);
      }
    }
    
    await albumsGateway.post(formdata);
  }

  return (
    <Container>
      <h1>Create Album</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Control type="text" placeholder="Album Name" name="name" onChange={e => setName(e.target.value)} value={name} />
        </Form.Group>
        <Form.Group controlId="formReleaseDay">
          <Form.Control type="text" placeholder="Album Release Day" name="releaseDay" onChange={e => setReleaseDay(e.target.value)} value={releaseDay} />
        </Form.Group>
        <Form.Group controlId="formReleaseMonth">
          <Form.Control type="text" placeholder="Album Release Month" name="releaseMonth" onChange={e => setReleaseMonth(e.target.value)} value={releaseMonth} />
        </Form.Group>
        <Form.Group controlId="formReleaseYear">
          <Form.Control type="text" placeholder="Album Release Year" name="releaseYear" onChange={e => setReleaseYear(e.target.value)} value={releaseYear} />
        </Form.Group>
        <Form.Group controlId="artists">
          <Form.Label>Artists</Form.Label>
          <InputGroup className="mb-2">
            <InputGroup.Prepend>
              <Button onClick={handleAddArtist}>Add</Button>
            </InputGroup.Prepend>
            <FormControl placeholder="Artist Id" onChange={e => setArtist({...artist, id: e.target.value})} value={artist.id} />
            <FormControl placeholder="Artist Name" onChange={e => setArtist({...artist, name: e.target.value})} value={artist.name} />
          </InputGroup>
          <FormList items={artists} removeItem={handleRemoveArtist} />
        </Form.Group>
        <Form.File
          id="albumImage"
          label={image.name}
          custom
          onChange={handleChange}
        />
        <Button variant="primary" type="submit">Submit</Button>
      </Form>
    </Container>
  );
}

export default CreateAlbum;