import React from 'react';
import Card from 'react-bootstrap/Card';
import Styled from 'styled-components';
import './latestnews.css';

var overlayStyle = {
    backgroundImage: '-webkit-linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.85))',
    backgroundImage: 'linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.85))'
}

const NewsCard = (props) => {
    const CardTitle = Styled(Card.Title)`
        font-size: 1.25rem;
        font-weight: 400;
        font-family: Oswald,sans-serif;
        @media (min-width: 768px) {
            font-size: 2.34375rem;
        }
    `;

    return (
        <a href={"/news/" + props.id} >
            <Card className="h-100 text-white bg-dark rounded-0 latest-news">
                <Card.Img variant="top" src={props.image} className="h-100 latest-news-img" />
                <Card.ImgOverlay className="d-flex flex-column justify-content-end" style={overlayStyle}>
                    <CardTitle className="m0">{props.title}</CardTitle>
                    <Card.Text className='latest-news-summary'>{props.summary}</Card.Text>
                </Card.ImgOverlay>
            </Card>
        </a>
    );
}

NewsCard.defaultProps = {
    id: '00000000-0000-0000-0000-000000000000',
    image: "",
    title: "",
    summary: ""
}

export default NewsCard;