import React from 'react';
import Styled from 'styled-components';
import { default as bootstrapCard } from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const BootstrapCardTitle = Styled(bootstrapCard.Title)`
  font-family: Oswald,Arial,sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

const BootstrapCard = Styled(bootstrapCard)`
  color: #000000;
  border-style: none;
  &:hover {
    background-color: #FF5A00;
    color: #FFFFFF;
  }
`;

const BootstrapCardImage = Styled(bootstrapCard.Img)`
  &:hover {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    transition: all 1s ease;
    -webkit-transition: all 1s ease;
  }
`;

const BootstrapCardBody = Styled(bootstrapCard.Body)`
`;

function Card(props) {
  if (props.variant === "left")
  {
    return (
      <BootstrapCard className="mx-auto mb-4">
        <Row className="no-gutters">
          <Col>
            <BootstrapCardImage variant="top" src={props.image} className="w-100" />
          </Col>
          <Col>
            <BootstrapCardBody>
              <BootstrapCardTitle>{props.title}</BootstrapCardTitle>
              <BootstrapCard.Text>{props.content}</BootstrapCard.Text>
            </BootstrapCardBody>
          </Col>
        </Row>
      </BootstrapCard>
    )
  }

  return (
    <BootstrapCard>
      <BootstrapCardImage variant="top" src={props.image} className="w-100" />
      <BootstrapCardBody>
        <BootstrapCardTitle>{props.title}</BootstrapCardTitle>
        <BootstrapCard.Text>{props.content}</BootstrapCard.Text>
      </BootstrapCardBody>
    </BootstrapCard>
  )
}

export default Card;