import Spinner from 'react-bootstrap/Spinner'
import React from 'react';

const spinnerStyle = {
    color: '#ff5a00'
};

function LoadingSpinner() {
  return (
    <Spinner animation="border" style={spinnerStyle} />
  );
}

export default LoadingSpinner;