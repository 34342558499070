import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Media from 'react-bootstrap/Media';

var authorStyle = {
  background: '#262626',
  color: '#ffffff',
  padding: '10px 0'
};

var imageStyle = {
  border: '#FFFFFF SOLID 4px',
  msBorderRadius: '50%',
  borderRadius: '50%',
  height: '100px',
  width: '100px',
}

var headerStyle = {
  textAlign: 'center',
  fontSize: '10px',
  textTransform: 'uppercase',
  fontWeight: '900',
  paddingTop: '5px'
}

var headerSpanStyle = {
  borderTop: '3px solid #ff5a00'
}

const ArticleAuthor = (props) => {
  return (
    <div style={authorStyle}>
      <Container>
        <Row>
          <Col sm={12}>
            <h3 style={headerStyle}><span style={headerSpanStyle}>About the Author</span></h3>
            <Media>
              <div className="media-left mr-2">
                <a href="/User/Alex-Rice">
                  <img style={imageStyle} className="media-object" alt={props.username} src={props.image} />
                </a>
              </div>
              <Media.Body>
                <h4 className="media-heading" id="media-heading">
                  <a className="light" href={"/Users/" + props.id}>{props.username}</a>
                </h4>
                <p>{props.biography}</p>
              </Media.Body>
            </Media>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

ArticleAuthor.defaultProps = {
  id : "",
  username: "",
  biography: "",
  image: ""
}

export default ArticleAuthor;