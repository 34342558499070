import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Components and Pages
import Header from '../components/header';
import User from './user';
import Home from './home';
import News from './news';
import CreateNews from './createnews';
import CreateArtist from './createartist';
import CreateAlbum from './createalbum';
import ReviewList from './reviewlist';
import Review from './review';
import FeatureList from './featurelist';
import CreateGenre from './creategenre';
import CreateReview from './createreview';
import CreateFeature from './createfeature';
import CreateBlogPost from './createblogpost';
import StaffList from './stafflist';
import Feature from './feature';
import Genre from './genre';
import GenreList from './genrelist';
import BoardList from './boardlist';
import Board from './board';
import BlogPost from './blogPost';
import SigninSignup from './signinsignup';
import SearchResults from './searchresults';
import Container from 'react-bootstrap/Container';

import './root.css';
import { ProvideAuth } from '../hooks/useAuth';

var dimmedStyle = {
  opacity: 0.3
};

const Root = () => {
  const [dimmed, setDimmed] = useState(false);

  const toggleDimmed = event => {
    setDimmed(!dimmed);
  }  

  return (
    <ProvideAuth>
      <Router>
        <Header onToggleDimmed={toggleDimmed} />
        <Container/>
        <main style={dimmed ? dimmedStyle : null}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/users/:id" exact component={User} />
            <Route path="/blogpost/create" exact component={CreateBlogPost} /> 
            <Route path="/blogpost/:id" exact component={BlogPost} />
            <Route path="/news/create" exact component={CreateNews} />
            <Route path="/genres/create" exact component={CreateGenre} />
            <Route path="/artists/create" exact component={CreateArtist} />
            <Route path="/albums/create" exact component={CreateAlbum} />
            <Route path="/news/:id" exact component={News} />
            <Route path="/staff" exact component={StaffList} />
            <Route path="/features" exact component={FeatureList} />
            <Route path="/features/create" exact component={CreateFeature} />
            <Route path="/features/:id" exact component={Feature} />
            <Route path="/reviews" exact component={ReviewList} />
            <Route path="/reviews/create" exact component={CreateReview} />
            <Route path="/reviews/:id" exact component={Review} />
            <Route path="/genres" exact component={GenreList} />
            <Route path="/genres/:id" exact component={Genre} />
            <Route path="/boards" exact component={BoardList} />
            <Route path="/boards/:id" exact component={Board} />
            <Route path="/signin-signup" component={SigninSignup} />
            <Route path="/search/:searchTerm" component={SearchResults} />
          </Switch>
        </main>
      </Router>
    </ProvideAuth>
  );
}

export default Root;