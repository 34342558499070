import React, { useState, useEffect } from 'react';
import PostCommentGateway from '../../gateways/postCommentsGateway';
import PostComment from '../postcomment';
import CreatePostComment from '../createpostcomment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import Styled from 'styled-components';

const CommentIcon = Styled(FontAwesomeIcon)`
  display: inline-block;
`;

function PostCommentList(props) {
  const [comments, setComments] = useState([]);
  const postCommentGateway = PostCommentGateway();
  const [showComments, setShowComments] = useState(false);

  useEffect(() => {
    fetchComments();
  }, []);

  const fetchComments = async () => {
    const postComments = await postCommentGateway.getAll(props.boardId, props.postId);
    setComments(postComments);
  }

  const handleShowComments = (e) => {
    e.preventDefault();
    setShowComments(!showComments);
  }

  return (
    <Row>
      <Col sm={12}>
        <CreatePostComment boardId={props.boardId} postId={props.postId} />
      </Col>
      <Col sm={12}>
        <CommentIcon icon={faComment} onClick={handleShowComments} /> {comments.length} 
      </Col>
      {showComments ? (
        comments.map(comment => (
          <Col sm={12}>
            <PostComment fetchComments={fetchComments} authorId={comment.author.id} authorName={comment.author.username} authorImage={comment.author.imageUrl} content={comment.content} postId={comment.id} createdDate={comment.createdDate} staff={comment.author.staff} moderation={comment.author.moderator} />
          </Col>
        ))
      ) : (
        null
      )}
    </Row>
  );
}

PostCommentList.defaultProps = {
  comments: 0
}

export default PostCommentList;