import { newsListUrl, newsUrl } from './urls';
import { useAuth } from '../hooks/useAuth';

const NewsGateway = () => {
  const auth = useAuth();
  const jwt = auth.jwt;

  async function getAll() {
    return await fetch(newsListUrl)
      .then(toJson);
  }

  async function get(id) {
    return await fetch(newsUrl(id))
      .then(toJson);
  }

  async function post(formdata) {
    var requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Authorization':  `bearer ${jwt}`
      }), 
      body: formdata,
      redirect: 'follow'
    };

    fetch(newsListUrl, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }
  
  function toJson(response){
    return response.json();
  }
  
  return Object.freeze({
      getAll,
      get,
      post
  });
}

export default NewsGateway;