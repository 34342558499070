import React from 'react';
import { useAuth } from '../../hooks/useAuth';
import Button from '../../components/button';

function CreateComment() {
  const auth = useAuth();
  const user = auth.getUser();

  return (
      user ? (
        <Button variant="primary" type="submit" text="Post" disabled={false} />
      ) : (
        <Button variant="primary" type="submit" text="Sign in to post" disabled={true} />
      )
  );
}

export default CreateComment;