import React, { useState, useEffect, Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Comments from '../../components/comments';
import Byline from '../../components/byline';
import LoadingSpinner from '../../components/loadingspinner';
import ArticleHeaderText from '../../components/articleheadertext';
import NewsGateway from '../../gateways/newsGateway';
import ArticleBody from '../../components/articlebody';

function News(props) {
  const [news, setNews] = useState({});
  const [loading, setLoading] = useState(true);
  const newsGateway = NewsGateway(); 

  useEffect(() => {
    const fetchNews = async () => {
      const retrievedNews = await newsGateway.get(props.match.params.id); 
      setNews(retrievedNews);
      setLoading(false);
    }
    fetchNews();
  }, []);

  return (
    loading ? (
      <Container>
        <LoadingSpinner />
      </Container>
    ) : (
      <Fragment>
        <ArticleHeaderText header={news.title} subheader={news.summary} />
        <Byline authorId={news.author.id} authorName={news.author.username} articleType="News" articleDate={news.createdDate} />
        <Container>
          <Row>
            <Col>
              <ArticleBody content={news.content} />
            </Col>
          </Row>
        </Container>
        <Comments articleId={props.match.params.id} />
      </Fragment>
    )      
  );
}

export default News;