import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReviewsGateway from '../../gateways/reviewsGateway';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import MarkDownEditor from '../../components/markdowneditor';

function CreateReview() {
  const id = uuidv4();
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [live, setLive] = useState(false);
  const [album, setAlbum] = useState("");
  const [score, setScore] = useState("");

  const reviewsGateway = ReviewsGateway();

  const handleLiveClick = () => setLive(!live);

  const handleSubmit = async (event) => {
    event.preventDefault();

    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("title", title);
    formdata.append("summary", summary);
    formdata.append("content", content);
    formdata.append("live", live);
    formdata.append("albumId", album);
    formdata.append("score", score);

    await reviewsGateway.post(formdata);
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formTitle">
          <Form.Control type="text" placeholder="Title" name="title" onChange={e => setTitle(e.target.value)} value={title} />
        </Form.Group>
        <Form.Group controlId="formSummary">
          <Form.Control type="text" placeholder="Summary" name="summary" onChange={e => setSummary(e.target.value)} value={summary} />
        </Form.Group>
        <Form.Group controlId="formAlbum">
          <Form.Control type="text" placeholder="Album" name="album" onChange={e => setAlbum(e.target.value)} value={album} />
        </Form.Group>
        <Form.Group controlId="formContent">
          <MarkDownEditor imagePrefix={id} simple={false} onChange={content => setContent(content)} placeholder={"Article Content"} />
        </Form.Group>
        <Form.Group controlId="formScore">
          <Form.Control type="text" placeholder="Score" name="score" onChange={e => setScore(e.target.value)} value={score} />
        </Form.Group>
        <Form.Group controlId="formLive">
          <Form.Check type="checkbox" label="Live" onClick={handleLiveClick} value={live} />
        </Form.Group>
        <Button variant="primary" type="submit">Publish</Button>
      </Form>
    </Container>
  )
}

export default CreateReview;