import React, { useState, useEffect, Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import LoadingSpinner from '../loadingspinner';
import NewsCard from './newscard';
import './latestnews.css';
import '../../gateways/newsGateway';
import NewsGateway from '../../gateways/newsGateway';
import Styled from 'styled-components';

const MainNewsStyle = Styled(Col)`
  @media (max-width: 575.98px) {
    padding-bottom: 1.5rem;
  }
`;

function LatestNews() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const newsGateway = NewsGateway();

  useEffect(() => {
    const fetchNews = async () => {
      const latestNews = await newsGateway.getAll();
      setNews(latestNews);
      setLoading(false);
    }
    fetchNews();
  }, []);

  return (
    <Container className="pt-4">
      <Row>
        {loading ? (
          <LoadingSpinner />
          ) : (
          <Fragment>
            <MainNewsStyle sm={8}>
              <NewsCard id={news[0].id} image={news[0].image} title={news[0].title} summary={news[0].summary} />
            </MainNewsStyle>
            <Col sm={4}>
              <NewsCard id={news[1].id} image={news[1].image} title={news[1].title} summary={news[1].summary} />
            </Col>
          </Fragment>
        )}
      </Row>
    </Container>
  );
}

export default LatestNews;