import { imageListUrl } from './urls';
import { useAuth } from '../hooks/useAuth';

const ImagesGateway = () => {
    const auth = useAuth();
    const jwt = auth.jwt;

    async function postArticleImages(articleId, formdata) {

        var requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Authorization':  `bearer ${jwt}`
            }), 
            body: formdata,
            redirect: 'follow'
        };

        let postResult = null;

        return await fetch(imageListUrl(articleId), requestOptions)
            .then(response => response.text())
            .catch(error => console.log('error: ', error));
    }

    async function getArticleImages(articleId) {
        return await fetch(imageListUrl(articleId))
            .then(toJson);
    }

    function toJson(response) {
        return response.json();
    }

    return Object.freeze({
        getArticleImages,
        postArticleImages
    });

}

export default ImagesGateway;