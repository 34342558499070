import React from 'react';
import Styled from 'styled-components';

const ArticleMenuItem = ({children, articleType, select}) => {
  const ArticleListItem = Styled.li`
    padding: 10px;
    & > a:not([href]):not([class]) {
      color: #909090;
    }
    & > a:not([href]):not([class]):hover {
      color: #000000;
    }
    ${ props => articleType === select && `
      background: #e4581d;
      & > a:not([href]):not([class]) {
        color: #ffffff;
      }
      & > a:not([href]):not([class]):hover {
        color: #ffffff;
      }
    `}
  `;

  return (
    <ArticleListItem>{children}</ArticleListItem>
  );
}

export default ArticleMenuItem;