import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import { LinkContainer } from "react-router-bootstrap";
import './sitelogo.css';

function SiteLogo() {
    return (
        <LinkContainer to="/">
            <Navbar.Brand>RAGNA<span>ROCK</span></Navbar.Brand>
        </LinkContainer>
    );
}
  
export default SiteLogo;