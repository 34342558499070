import React, { useState, useEffect } from 'react';
import PostCommentsGateway from '../../gateways/postCommentsGateway';
import Form from 'react-bootstrap/Form';
import Button from '../../components/button';
import { useAuth } from '../../hooks/useAuth';
import Styled from 'styled-components';

const FormRow = Styled(Form.Row)`
  margin-top: 5px;
  margin-left: 0;
  margin-right: 0;
`;

const Input = Styled.input`
  border-radius: 0;
  border: 1px solid rgba(0,0,0,0.125);
  margin-right: 5px;
`;

function CreatePostComment(props) {
  const [content, setContent] = useState("");
  const boardId = props.boardId;
  const postId = props.postId;
  const postsCommentsGateway = PostCommentsGateway();

  const auth = useAuth();
  const user = auth.getUser();

  const handleSubmit = async (event) => {
    event.preventDefault();

    var formData = new FormData();
    formData.append("content", content);

    // Post Form
    const result = await postsCommentsGateway.post(boardId, postId, formData);
    const commentId = JSON.parse(result).id;

    // Refresh Page
    props.fetchPosts();
  }

  if(user) {
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <FormRow controlid="formContent">
            <Input type="content" type="text" name="content" onChange={e => setContent(e.target.value)} value={content}/>
            <Button variant="primary" type={"submit"} text={"Post"} />
          </FormRow>
        </Form>
      </React.Fragment>
    );
  } else {
    return (null);
  }
}

export default CreatePostComment;