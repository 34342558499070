import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import GenresGateway from '../../gateways/genresGateway';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormList from './formlist';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import MarkDownEditor from '../../components/markdowneditor';

function CreateGenre() {
  const id = uuidv4();
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState({preview: '', raw: '', name: ''});
  const [videos, setVideos] = useState([]);
  const [live, setLive] = useState(false);
  const [video, setVideo] = useState("");
  const [artist, setArtist] = useState({id: '', name: ''});
  const [artists, setArtists] = useState([]);

  const genresGateway = GenresGateway();

  const handleChange = (event) => {   
    setImage({
      preview: URL.createObjectURL(event.target.files[0]),
      raw: event.target.files[0],
      name: event.target.files[0].name
    })
  }

  const handleRemoveArtist = (name) => {
    setArtists(artists.filter(el => el !== name));
  }

  const handleRemoveVideo = (name) => {
    setVideos(videos.filter(el => el !== name));
  }

  const handleLiveClick = () => setLive(!live)

  const handleAddArtist = () => {
    if(!artists.includes(artist)) {
      setArtists([...artists, artist]);
    }
  }

  const handleAddVideo = () => {
    if(!videos.includes(video)) {
      setVideos([...videos, video]);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("title", title);
    formdata.append("summary", summary);
    formdata.append("content", content);
    formdata.append("image", image.raw);
    formdata.append("live", live);

    videos.forEach(v => {
      formdata.append('videos', v);
    });

    for (let i = 0; i < artists.length; i++) {
      for (let key of Object.keys(artists[i])) {
        formdata.append(`artists[${i}][${key}]`, artists[i][key]);
      }
    }

    await genresGateway.post(formdata);
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formTitle">
          <Form.Control type="text" placeholder="Title" name="title" onChange={e => setTitle(e.target.value)} value={title} />
        </Form.Group>
        <Form.Group controlId="formSummary">
          <Form.Control type="text" placeholder="Summary" name="summary" onChange={e => setSummary(e.target.value)} value={summary} />
        </Form.Group>
        <Form.Group controlId="formContent">
          <MarkDownEditor imagePrefix={id} simple={false} onChange={content => setContent(content)} placeholder={"Article Content"} />
        </Form.Group>
        <Form.Group controlId="formLive">
          <Form.Check type="checkbox" label="Live" onClick={handleLiveClick} value={live} />
        </Form.Group>
        <Form.File
          id="genreImage"
          label={image.name}
          custom
          onChange={handleChange}
        />
        <Form.Group controlId="videos">
          <Form.Label>Videos</Form.Label>
          <InputGroup className="mb-2">
            <InputGroup.Prepend>
              <Button onClick={handleAddVideo}>Add</Button>
            </InputGroup.Prepend>
            <FormControl placeholder="Video" onChange={e => setVideo(e.target.value)} value={video} />
          </InputGroup>
          <FormList items={videos} removeItem={handleRemoveVideo} />
        </Form.Group>
        <Form.Group controlId="artists">
          <Form.Label>Artists</Form.Label>
          <InputGroup className="mb-2">
            <InputGroup.Prepend>
              <Button onClick={handleAddArtist}>Add</Button>
            </InputGroup.Prepend>
            <FormControl placeholder="Artist Id" onChange={e => setArtist({...artist, id: e.target.value})} value={artist.id} />
            <FormControl placeholder="Artist Name" onChange={e => setArtist({...artist, name: e.target.value})} value={artist.name} />
          </InputGroup>
          <FormList items={artists} removeItem={handleRemoveArtist} />
        </Form.Group>
        <Button variant="primary" type="submit">Publish</Button>
      </Form>
    </Container>
  )
}

export default CreateGenre;