import React, { useState, useEffect, Fragment } from 'react';
import Comments from '../../components/comments';
import LoadingSpinner from '../../components/loadingspinner';
import ArticleHeaderLargeImage from '../../components/articleheaderlargeimage';
import ArticleAuthor from '../../components/articleauthor';
import SocialMedia from '../../components/socialmedia';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Byline from '../../components/byline';
import ArticleBody from '../../components/articlebody';
import FeaturesGateway from '../../gateways/featuresGateway';

function Feature(props) {
  const [feature, setFeature] = useState({});
  const [loading, setLoading] = useState(true);
  const gateway = FeaturesGateway();

  useEffect(() => {
    const fetchFeature = async () => {
      const feature = await gateway.get(props.match.params.id);
      setFeature(feature);
      setLoading(false);
    }

    fetchFeature();
  }, []);

  return (
    loading ? (
      <LoadingSpinner />
    ) : (
      <Fragment>
        <ArticleHeaderLargeImage title={feature.title} image={feature.featuredImage} />
        <SocialMedia title={feature.title} />
        <Container>
          <Row>
            <Col>
              <h2>{feature.summary}</h2>
              <Byline authorId={feature.user.id} authorName={feature.user.username} articleType="Feature" articleDate={feature.publishDate} />
              <ArticleBody content={feature.content} />
            </Col>
          </Row>
        </Container>
        <ArticleAuthor />
        <Comments articleId={props.match.params.id} />
      </Fragment>
    )
  );
}    

export default Feature;