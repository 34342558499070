import React from 'react';
import Media from 'react-bootstrap/Media';
import AuthorLink from '../../components/authorLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import Styled from 'styled-components';
import Moment from 'react-moment';

const StyledMedia = Styled(Media)`
  margin-bottom: 15px;
`;

const Avatar = Styled.img`
  border-radius: 50%;
`;

const Username = Styled.h5`
  font-size: 13px;
  margin-bottom: 0;
`;

const Time = Styled(Moment)`
  display: inline-block;
`;

const PostClock = Styled(FontAwesomeIcon)`
  display: inline-block;
`;

const Comment = (props) => {  
  return (
    <StyledMedia key={props.postId}>
      <div className="media-left mr-2">
        <a href="/User/Alex-Rice">
          <Avatar 
            width={40}
            height={40}
            className="mr-1"
            alt={props.authorName} 
            src={props.authorImage} />
        </a>
      </div>
      <Media.Body>
        <Username className="media-heading" id="media-heading">
          <AuthorLink authorId={props.authorId} authorName={props.authorName}></AuthorLink> {props.staff ? '// Staff' : props.moderator ? '// Moderator' : ''}
          <br />
          <PostClock icon={faClock} /> <Time fromNow>{props.createdDate}</Time>
        </Username>
        {props.content}
      </Media.Body>
    </StyledMedia>
  )
}

Comment.defaultProps = {
  authorId: '00000000-0000-0000-0000-000000000000',
  authorName: '',
  authorImage: '',
  content: '',
  postId: '00000000-0000-0000-0000-000000000000',
  createdDate: '1983-10-31T12:59-0500',
  staff: false,
  moderator: true
}

export default Comment