import React from 'react';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faReddit } from '@fortawesome/free-brands-svg-icons'
import { faComment } from '@fortawesome/free-solid-svg-icons'

// https://www.pcgamer.com/uk/geforce-now-is-the-cloud-gaming-service-stadia-should-be/#comment-jump
// https://www.npmjs.com/package/@fortawesome/react-fontawesome
// https://www.pcgamer.com/uk/legends-of-runeterra-review/
// https://www.polygon.com/2020/2/4/20754666/the-sims-20th-anniversary-ea-maxis
// https://stackoverflow.com/questions/56559772/where-do-i-find-the-object-names-of-icons-in-the-fontawesome-free-packages

var socialMediaListStyle = {
  padding: '0',
  margin: '0',
  float: 'Right'
}

var socialMediaListItemStyle = {
  display: 'inline',
  marginRight: '5px'
}

var lastSocialMediaListItemStyle = {
  display: 'inline'
}

var facebookStyle = {
  fontSize: '2em',
  lineHeight: '32px'
}

var twitterStyle = {
  fontSize: '2em',
  lineHeight: '32px'
}

var redditStyle = {
  fontSize: '2em',
  lineHeight: '32px'
}

var commentStyle = {
  fontSize: '2em',
  lineHeight: '32px'
}

function SocialMedia(props) {
  return (
    <ul style={socialMediaListStyle}>
      <li style={socialMediaListItemStyle}><a href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} color="#3B5998" style={facebookStyle} /></a></li>
      <li style={socialMediaListItemStyle}><a href={"https://twitter.com/intent/tweet?text=" + props.title + "&url=" + window.location.href} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} color="#4099FF" style={twitterStyle} /></a></li>
      <li style={socialMediaListItemStyle}><a href={"http://www.reddit.com/submit?url=" + window.location.href + "&title=" + props.title} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faReddit} color="#FF5700" style={redditStyle} /></a></li>
      <li style={lastSocialMediaListItemStyle}><a href={"#comments"}><FontAwesomeIcon icon={faComment} color="#FF5700" style={commentStyle} /></a></li>
    </ul>
  );
}

export default SocialMedia;