import React from 'react';

const AuthorLink = (props) => {
  return (
    <a href={"/users/" + props.authorId}>{props.authorName}</a>
  );
}

AuthorLink.defaultProps = {
  authorId: '00000000-0000-0000-0000-000000000000',
  authorName: ""
}

export default AuthorLink;