import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import "./createnews.css";
import NewsGateway from '../../gateways/newsGateway';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { v4 as uuidv4 } from 'uuid';
import MarkDownEditor from '../../components/markdowneditor';

function CreateNews() {
  const [id, setId] = useState(uuidv4());
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("<p>Initial content</p>");
  const [image, setImage] = useState({preview: '', raw: '', name: ''});
  const [live, setLive] = useState(false);

  const newsGateway = NewsGateway();

  const handleLiveClick = () => setLive(!live);

  const handleChange = (event) => {   
    setImage({
      preview: URL.createObjectURL(event.target.files[0]),
      raw: event.target.files[0],
      name: event.target.files[0].name
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("title", title);
    formdata.append("content", content);
    formdata.append("live", live);
    formdata.append("summary", summary);
    formdata.append("image", image.raw);

    await newsGateway.post(formdata);
  }

  var formStyle = {
    marginTop: "20px",
    marginBottom: "20px"
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit} style={formStyle}>
        <Form.Group controlId="formTitle">
          <Form.Control type="text" placeholder="Article Title" name="title" onChange={e => setTitle(e.target.value)} value={title} />
        </Form.Group>
        <Form.Group controlId="formSummary">
          <Form.Control type="text" placeholder="Article Summary" name="summary" onChange={e => setSummary(e.target.value)} value={summary} />
        </Form.Group>
        <Form.Group controlId="formContent">
          <MarkDownEditor imagePrefix={id} simple={false} onChange={content => setContent(content)} placeholder={"Article Content"} />
        </Form.Group>
        <Form.Group controlId="formLive">
          <Form.Check type="checkbox" label="Live" onClick={handleLiveClick} value={live} />
        </Form.Group>
        <Form.File
          id="articleImage"
          label={image.name}
          custom
          onChange={handleChange}
        />
        <Button variant="primary" type="submit">Publish</Button>
      </Form>
    </Container>
  );
}

export default CreateNews;