import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Comment from './comment';
import CreateComment from './createcomment';
import CommentsGateway from '../../gateways/commentsgateway';
import LoadingSpinner from '../../components/loadingspinner';
import Styled from 'styled-components';

function Comments({articleId}) {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const commentsGateway = CommentsGateway();

  const Comments = Styled(Container)`
    padding-top: 20px;
  `;

  const CommentsWrapper = Styled.div`
  `;

  const CommentHeaderStyle = Styled.h2`
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  `;

  useEffect(() => {
    fetchComments();
  }, [articleId]);

  const fetchComments = async () => {
    const comments = await commentsGateway.getArticleComments(articleId);
    setComments(comments);
    setLoading(false);
  } 

  return (
    <CommentsWrapper id="comments">
      <Comments>
        <Row>
          <Col sm={12} id="add-comment">
            <CommentHeaderStyle>{comments?.item2?.length} Comments</CommentHeaderStyle>
            <CreateComment articleId={articleId} fetchComments={fetchComments} />
          </Col>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Col sm={12} id="comments">
              {comments.item2.map(comment => (
                <Comment
                  key={comment.id}
                  authorid={comment.author.id} 
                  authorName={comment.author.username} 
                  authorImage={comment.author.imageUrl}
                  content={comment.content}
                  id={comment.id}
                  createdDate={comment.createdDate}
                  staff={comment.author.staff}
                  moderator={comment.author.moderator}
                />
              ))}
            </Col>
          )}
        </Row>
      </Comments>
    </CommentsWrapper>
  );
}
  
export default Comments;