import { articleCommentsUrl, commentsUrl } from './urls';
import { useAuth } from '../hooks/useAuth';

export default function CommentsGateway(){
  const auth = useAuth();
  const jwt = auth.jwt;

  async function get() {
    return await fetch(commentsUrl)
      .then(toJson);
  }

  async function getArticleComments(articleId) {
    return await fetch(articleCommentsUrl(articleId))
      .then(toJson);
  }
  
  async function post(formdata, articleId) {
    var requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Authorization':  `bearer ${jwt}`
      }), 
      body: formdata,
      redirect: 'follow'
    };

    return await fetch(articleCommentsUrl(articleId), requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  function toJson(response){
    return response.json();
  }
  
  return  Object.freeze({
      get,
      getArticleComments,
      post
  });
}