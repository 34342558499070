import React, { useState, Fragment } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useAuth } from '../../hooks/useAuth';

function Signup() {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const auth = useAuth();

  const handleSignup = event => {
    event.preventDefault();
    auth.signup(username, email, password);

    // ToDo Signin after email
    auth.signin(email, password);
  }

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  }

  const handleRepeatPasswordChange = event => {
    setRepeatPassword(event.target.value);

    if(password === event.target.value) {
      event.target.setCustomValidity("");
    } else {
      event.target.setCustomValidity("Passwords do not match");
    }
  }

  return (
    <Fragment>
        <h3>Sign up for a new account</h3>
        <Form onSubmit={handleSignup}>
            <Form.Group controlId="Username">
                <Form.Label>Username</Form.Label>
                <Form.Control type="username" placeholder="Enter username" required onChange={e => setUsername(e.target.value)} />
                <Form.Control.Feedback type="invalid">
                Please provide a valid username.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="Email">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" onChange={e => setEmail(e.target.value)} />
                <Form.Control.Feedback type="invalid">
                Please provide a valid email.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="Password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" required onChange={handlePasswordChange} />
                <Form.Control.Feedback type="invalid">
                Please provide a valid password.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="RepeatPassword">
                <Form.Label>Repeat Password</Form.Label>
                <Form.Control type="password" placeholder="Repeat Password" required onChange={handleRepeatPasswordChange} />
                <Form.Control.Feedback type="invalid">
                Please provide a valid password.
                </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">
                Create Account
            </Button>
        </Form>
    </Fragment>
  );
}

export default Signup;