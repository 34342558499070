import React, { Fragment, useEffect, useState } from 'react';
import Card from '../../components/card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import GenresGateway from '../../gateways/genresGateway';
import LoadingSpinner from '../../components/loadingspinner';
import { truncateText } from '../../utils';

function GenreList() {
  const [genres, setGenres] = useState([]);
  const [loading, setLoading] = useState(true);
  const genresGateway = GenresGateway();

  useEffect(() => {
    const fetchGenres = async () => {
      const genres = await genresGateway.getAll();
      setGenres(genres);
      setLoading(false);
    }
    fetchGenres();
  }, []);

  return (
    loading ? (
      <LoadingSpinner />
    ) : (
      <Container>
        <h3>Genres</h3>
        <Row xs={3} md={3} className="mb-4">
          {genres.map(genre => (
            <Col>
              <a href={"/genres/" + genre.id}>
                <Card 
                  title={genre.title}
                  content={truncateText(genre.content,100)}
                  image={genre.image}
                />
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    )
  );
}

export default GenreList;