import React from 'react';
import MarkDownRenderer from '../markdownrenderer';

const ArticleBody = (props) => {
    return (
        <MarkDownRenderer 
            content={props.content}
        />
    );
}

export default ArticleBody;