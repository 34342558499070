const BaseUrl = 'https://ragnarockapi.azurewebsites.net/api/v1/'
//const BaseUrl = 'https://localhost:44365/api/v1/'

export function userArticleListUrl(userId)
{
    return `${BaseUrl}/users/${userId}/articles/`;
}

export function articleListUrl(type)
{
    return `${BaseUrl}articles/${type}`;
}

export function imageListUrl(articleId)
{
    return `${BaseUrl}articles/${articleId}/images`
}

export const blogListUrl = `${BaseUrl}blogs`;
export function blogUrl(id)
{
    return `${BaseUrl}blogs/${id}`;
}
export function userBlogListUrl(id)
{
    return `${BaseUrl}Users/${id}/Blogs/`;
}

export function postListUrl(boardId) {
    return `${BaseUrl}boards/${boardId}/posts`;
}
export function postUrl(boardId, postId) {
    return `${BaseUrl}boards/${boardId}/posts/${postId}`;
}

export function postCommentListUrl(boardId, postId) {
  return `${BaseUrl}boards/${boardId}/posts/${postId}/comments`;
}

export const featureListUrl = `${BaseUrl}features`;
export function featureUrl(id)
{
    return `${BaseUrl}features/${id}`;
}

export const albumListUrl = `${BaseUrl}albums`
export function albumUrl(id)
{
  return `${BaseUrl}albums/${id}`;
}

export const genreListUrl = `${BaseUrl}genres`
export function genreUrl(id)
{
    return `${BaseUrl}genres/${id}`;
}

export const artistsListUrl = `${BaseUrl}artists`
export function artistsUrl(id)
{
  return `${BaseUrl}artists/${id}`; 
}

export const newsListUrl = `${BaseUrl}news`
export function newsUrl(id)
{
  return `${BaseUrl}news/${id}`;
}

export const reviewListUrl = `${BaseUrl}reviews`
export function reviewUrl(id)
{
    return `${BaseUrl}reviews/${id}`;
}

export const signupUrl = `${BaseUrl}users/register`
export const signinUrl = `${BaseUrl}users/authenticate`
export const userListUrl = `${BaseUrl}users`
export const staffUrl = `${BaseUrl}users?staff=true`
export function userUrl(id)
{
    return `${BaseUrl}users/${id}`;
}

export const commentsUrl = `${BaseUrl}comments`

export function articleCommentsUrl(articleId)
{
    return `${BaseUrl}articles/${articleId}/comments`;
}