import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Signup from './signup';
import Signin from './signin';

function SigninSignup() {
  return (
    <Container>
      <Row>
        <Col sm={6}>
          <Signup />
        </Col>
        <Col sm={6}>
          <Signin />
        </Col>
      </Row>
    </Container>
  );
}

export default SigninSignup;