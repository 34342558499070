import { userListUrl, userUrl, staffUrl, signinUrl, signupUrl  } from './urls';

export default function UsersGateway(){
  async function getAll() {
    return await fetch(userListUrl)
      .then(toJson);
  }

  async function getStaff() {
    return await fetch(staffUrl)
      .then(toJson);
  }

  async function signup(raw) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    return await fetch(signupUrl, requestOptions)
      .then(response => response.text())
      .catch(error => console.log('error', error));
  }

  async function signin(raw) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return await fetch(signinUrl, requestOptions)
      .then(response => response.text())
      .catch(error => console.log('error', error));
  }

  async function get(id) {
    return await fetch(userUrl(id))
      .then(toJson);
  }
  
  function toJson(response){
    return response.json();
  }
  
  return  Object.freeze({
      getAll,
      get,
      getStaff,
      signup,
      signin
  });
}