import React, { useEffect, useContext, createContext } from "react";
import UsersGateway from '../gateways/usersgateway';
import User from './user';

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const gateway = UsersGateway();

  const [jwt, setJwt] = React.useState(
    localStorage.getItem('jwt') || null
  );
  
  const extractUserFromJwt = () => {
    if (jwt) {
      try {
        let data = JSON.parse(atob(jwt.split('.')[1]));
        let user = new User(data);
        return user;
      } catch (error) {
        console.log(error);
        // ignore
      }
    }
    return null;
  }

  const getUser = () => {
    let user = extractUserFromJwt();
    return user;
  };

   const signin = async (email, password) => {
    var raw = JSON.stringify({"email":email, "password":password});
    let response = await gateway.signin(raw);

    console.log(response);

    setJwt(response);
  };

  const signup = async (username, email, password) => {
    var raw = JSON.stringify({"username":username, "email":email, "password":password});
    let response = await gateway.signup(raw);

    // ToDo Redirect to await email page
  };

  const signout = () => {
    localStorage.removeItem('jwt');
    setJwt(null);
  };

  const sendPasswordResetEmail = email => {
    return true;
  };

  const confirmPasswordReset = (code, password) => {
    return true;
  };

  useEffect(() => {
    localStorage.setItem('jwt', jwt);
  }, [jwt]);
  
  // Return the user object and auth methods
  return {
    jwt,
    getUser,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    User
  };
}