import React, { useState } from 'react';
import LatestNews from '../../components/latestnews';
import LatestReviews from '../../components/latestreviews';
import LatestArticles from '../../components/latestarticles';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ArticleMenu from '../../components/articlemenu';

function Home() {
  const [articleType, setArticleType] = useState("all");

  return (
    <React.Fragment>
      <LatestNews />
      <LatestReviews />
      <Container className="pt-4">
        <Row>
          <Col sm={3}>
            <ArticleMenu handleArticleType={(a) => setArticleType(a)} articleType={articleType} />
          </Col>
          <Col sm={9}>
            <LatestArticles articleType={articleType} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Home;