import React, { useState, useEffect, Fragment } from 'react';
import LoadingSpinner from '../../components/loadingspinner';
import ArticleHeaderLargeImage from '../../components/articleheaderlargeimage';
import ArticleAuthor from '../../components/articleauthor';
import SocialMedia from '../../components/socialmedia';
import ArticleBody from '../../components/articlebody';
import GenresGateway from '../../gateways/genresGateway';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Comments from '../../components/comments';
import Byline from '../../components/byline';
import YoutubeVideo from '../../components/youtubevideo';

function Genre(props) {
  const [genre, setGenre] = useState({});
  const [loading, setLoading] = useState(true);
  const genreGateway = GenresGateway(); 

  useEffect(() => {
    const fetchGenre = async () => {
      const retrievedGenre = await genreGateway.get(props.match.params.id); 
      setGenre(retrievedGenre);
      setLoading(false);
    }

    fetchGenre();
  }, []);

  return (
    loading ? (
      <LoadingSpinner />
    ) : (
      <Fragment>
        <ArticleHeaderLargeImage title={genre.title} image={genre.image} />
        <SocialMedia title={genre.title} />
        <Container>
          <Row>
            <Col>
              <h2>{genre.summary}</h2>
              <Byline authorId={genre.author.id} authorName={genre.author.username} articleType="Genre" articleDate={genre.createdDate} />
              <ArticleBody content={genre.content} />
            </Col>
          </Row>
          <Row className="mb-4">
            {genre.videos.map(video => (
              <Col sm={4}><YoutubeVideo videoId={video} /></Col>
            ))}
          </Row>
        </Container>
        <ArticleAuthor id={genre.author.id} username={genre.author.username} biography={genre.author.biography} image={genre.author.imageUrl} />
        <Comments articleId={props.match.params.id} />
      </Fragment>
    )      
  );
}

export default Genre;