import React from 'react';
import MarkdownView from 'react-showdown';
import * as youtube from '../../helpers/ShowdownYoutube.js';

const MarkDownRenderer = (props) => {
    return (
        <MarkdownView
            markdown={props.content}
            options={{ tables: true, emoji: true }}
            extensions="youtube"
        />
    );
}

export default MarkDownRenderer;