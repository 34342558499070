import React, { useState, useEffect, Fragment } from 'react';
import LatestBlogPost from './latestBlogPost';
import BlogGateway from '../../gateways/blogGateway';
import LoadingSpinner from '../../components/loadingspinner';

function LatestUserBlogPosts(props) {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const blogGateway = BlogGateway();
    
    useEffect(() => {
        const fetchBlogs = async () => {
            const latestUserBlogs = await blogGateway.getAllByUser(props.userid);
            setBlogs(latestUserBlogs);
            setLoading(false);
        }
        fetchBlogs();
    }, [])

    return (
        <Fragment>
            {loading ? (
                <LoadingSpinner />
            ) : (
                blogs.map(blog => (
                    <LatestBlogPost 
                        key={blog.id}
                        title={blog.title}
                        createdDate={blog.createdDate}
                        id={blog.id}
                    />
                ))
            )}
        </Fragment>
    )
}

export default LatestUserBlogPosts;