import React, { useState, useEffect, Fragment } from 'react';
import BlogGateway from '../../gateways/blogGateway';
import SocialMedia from '../../components/socialmedia';
import ArticleBody from '../../components/articlebody';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Comments from '../../components/comments';
import Byline from '../../components/byline';
import LoadingSpinner from '../../components/loadingspinner';
import LatestUserBlogPosts from '../../components/latestuserblogposts';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

function BlogPost(props) {
    const [blog, setBlog] = useState({});
    const [loading, setLoading] = useState(true);
    const blogGateway = BlogGateway();

    useEffect(() => {
        const fetchNews = async () => {
            const retrievedBlog = await blogGateway.get(props.match.params.id); 
            setBlog(retrievedBlog);
            setLoading(false);
        }

        fetchNews();
    }, []);

    return (
        loading ? (
            <LoadingSpinner />
        ) : (
            <Fragment>
              <SocialMedia title={blog.title} />
              <Container>
                <Row>
                  <Col sm={8}>
                    <h1>{blog.title}</h1>
                    <Byline authorId={blog.author.id} authorName={blog.author.username} articleType="Blog" articleDate={blog.createdDate} />
                    <ArticleBody content={blog.content} />
                  </Col>
                  <Col sm={4}>
                    <Link to="/blogpost/create">
                      <Button variant="primary">Publish a new blog post</Button>
                    </Link>
                    <LatestUserBlogPosts userid={blog.author.id} />
                  </Col>
                </Row>
              </Container>
              <Comments articleId={props.match.params.id} />
            </Fragment>
        )
    );
}

export default BlogPost;