import React from 'react';
import Media from 'react-bootstrap/Media';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

const LatestBlogPost = (props) => {
    return (
        <Media key={props.id}>
            <Media.Body>
                <Link to={"/blogpost/" + props.id}>
                    <h5>{props.title}</h5>
                </Link>
                <Moment fromNow>{props.createdDate}</Moment>
            </Media.Body>
        </Media>
    )
}

LatestBlogPost.defaultProps = {
    id: '00000000-0000-0000-0000-000000000000',
    title: '',
    createdDate: ''
}

export default LatestBlogPost