export default class User {
    id;
    unique_name;
    image;
    twitter;
    biography;
    role = [];

    constructor(data) {
        this.id = data.nameid;
        this.username = data.unique_name;
        this.image = data.image;
        this.twitter = data.twitter;
        this.biography = data.biography;
        this.role = data.role;

        // Assign all Vars
        // Object.assign(this, data);
    }

    get isWriter() {
        if (this.role.includes("Writer"))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    get isEditor() {
        if (this.role.includes("Editor"))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    get isAdmin() {
        if (this.role.includes("Admin"))
        {
            return true;
        }
        else
        {
            return false;
        }
    }
}