import React, { useState } from 'react';
import * as Showdown from "showdown";
import Form from 'react-bootstrap/Form'
import FeaturesGateway from '../../gateways/commentsgateway';
import MarkdownEditor from '../../components/markdowneditor';
import Styled from 'styled-components';
import SubmitButton from './submitbutton'

const StyledForm = Styled(Form)`
  margin-top: 15px;
`;

const StyledFormText = Styled(Form.Text)`
  margin-bottom: 15px;
`;

function CreateComment(props) {
  const [content, setContent] = useState("");
  const gateway = FeaturesGateway();
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    if(props.articleId !== '00000000-0000-0000-0000-000000000000')
    {
      // Convert Markdown
      var converter = new Showdown.Converter({tables: true, simplifiedAutoLink: true});
      var markDown = converter.makeMarkdown(content);

      // Post Form
      var formData = new FormData();
      formData.append("content", markDown);
      await gateway.post(formData, props.articleId);

      // Refresh Comments
      props.fetchComments();

    }
    else
    {
      console.log('ArticleId is blank');
    }
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormText className="text-muted">
        RagnaRock has a zero tolerance policy when it comes to toxic conduct in comments. Any abusive, racist, sexist, threatening, bullying, vulgar, and otherwise objectionable behavior will result in moderation and/or account termination. Please keep your discussion civil.
      </StyledFormText>
      <MarkdownEditor onChange={content => setContent(content)} placeholder={'What do you think?'} mode='basic' />
      <SubmitButton />
    </StyledForm>
  );
}

CreateComment.defaultProps = {
  articleId: '00000000-0000-0000-0000-000000000000'
}

export default CreateComment;