import React from 'react';
import Styled from 'styled-components';

const ReviewScore = Styled.div`
  width: 2em;
  height: 2em;
  box-sizing: initial;
  background: #ffffff;
  border: 0.2em solid #FF5A00;
  color: #666666;
  text-align: center;
  border-radius: 50%; 
  line-height: 2em;
  box-sizing: content-box;
  font-weight: 400;
  font-family: Oswald,sans-serif;
`;

const BasicScore = (props) => {
  return (
    <ReviewScore>{props.score}</ReviewScore>
  )
}

BasicScore.defaultProps = {
  score: 5
};

export default BasicScore;