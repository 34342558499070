import React, { useState, useEffect } from 'react';
import PostGateway from '../../gateways/postsGateway';
import PostList from '../../components/postlist';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CreatePost from '../../components/createpost';

function BoardList() {
  const boardId = "a586a98b-88ba-4a8b-a977-387e2ca076eb";

  const [posts, setPosts] = useState([]);
  const gateway = PostGateway();

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    const latestPosts = await gateway.getAll(boardId);
    setPosts(latestPosts);
  }

  return (
    <Container>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h2>Boards</h2>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <CreatePost boardId={boardId} />
        </Col>
      </Row>
      <PostList boardId={boardId} />
    </Container>
  );
}

export default BoardList;