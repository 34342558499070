import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Editor } from '@tinymce/tinymce-react';
import * as Showdown from "showdown";
import FeaturesGateway from '../../gateways/featuresGateway';

function CreateFeature() {
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("<p>Initial content</p>");
  const [image, setImage] = useState({preview: '', raw: ''})
  const gateway = FeaturesGateway();

  const handleChange = (event) => {
    setImage({
      preview: URL.createObjectURL(event.target.files[0]),
      raw: event.target.files[0]
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Convert Markdown
    var converter = new Showdown.Converter({tables: true, simplifiedAutoLink: true});
    var markDown = converter.makeMarkdown(content);

    var formdata = new FormData();
    formdata.append("title", title);
    formdata.append("content", markDown);
    formdata.append("summary", summary);
    formdata.append("image", image.raw);

    await gateway.post(formdata);
  }

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <input type="title" type="text" name="title" onChange={e => setTitle(e.target.value)} value={title}/>
        <input type="summary" type="text" name="summary" onChange={e => setSummary(e.target.value)} value={summary}/>
        <Editor
          apiKey="vvy1r3ob4ptjo7ze0np5hynt80m3ejlcn9okylsii2l0tw9e"
          init={{
          height: 500,
          menubar: false,
          plugins: [
              'advlist autolink lists link image', 
              'charmap print preview anchor help',
              'searchreplace visualblocks code',
              'insertdatetime media table paste wordcount'
          ],
          toolbar:
              'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help'
          }}
          value={content} onEditorChange={content => setContent(content)}
        />
        <input type="file" id="upload-button" onChange={handleChange}/>
        <button type="submit">Submit</button>
      </form>
    </Container>
  );
}

export default CreateFeature;