import React from 'react';
import AuthorLink from '../authorLink';
import Moment from 'react-moment';
import Container from 'react-bootstrap/Container';
import Media from 'react-bootstrap/Media';
import SocialMedia from '../../components/socialmedia';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styled from 'styled-components';

const StyledImage = Styled.img`
  ms-border-radius: 50%;
  border-radius: 50%;
  height: 60px;
  width: 60px;
`;

const StyledText = Styled.p`
  margin: 0px;
`;

const StyledContainer = Styled(Container)`
  margin-bottom: 16px;
`;

const Byline = (props) => {
  return (
    <StyledContainer>
      <Row>
        <Col>
          <Media>
            <div className="media-left mr-2">
              <a href="/User/Alex-Rice">
                <StyledImage className="media-object" alt={props.authorName} src={props.authorImage} />
              </a>
            </div>
            <Media.Body>
              <StyledText><a href="/news">{props.articleType}</a> by <AuthorLink authorId={props.authorId} authorName={props.authorName}></AuthorLink>, Contributor</StyledText>
              <StyledText>Created <Moment fromNow>{props.articleDate}</Moment></StyledText>
            </Media.Body>
          </Media>
        </Col>
        <Col>
          <SocialMedia title={props.title} />
        </Col>
      </Row>
    </StyledContainer>
  );
}

Byline.defaultProps = {
  authorId: '00000000-0000-0000-0000-000000000000',
  authorName: "RagnaRock Staff",
  articleType: "Article",
  articleDate: "1976-04-19T12:59-0500",
  authorImage: "https://ragnarockstorage.blob.core.windows.net/userimages/ragna_rock.png"
}

export default Byline;