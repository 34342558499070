import React from 'react';
import Styled from 'styled-components';
import ArticleMenuItem from './articlemenuitem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper, faExclamation, faStarHalfAlt, faGuitar } from '@fortawesome/free-solid-svg-icons';

const ArticleMenu = ({handleArticleType, articleType}) => {

  const ArticleList = Styled.ul`
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    list-style-type: none;
  `;

  const handleClick = (articleType) => {
    handleArticleType(articleType);
  }

  return (
    <ArticleList>
        <ArticleMenuItem articleType={articleType} select="all"><a onClick={() => handleArticleType("all")}><FontAwesomeIcon icon={faExclamation} fixedWidth /> Latest</a></ArticleMenuItem>
        <ArticleMenuItem articleType={articleType} select="News"><a onClick={() => handleArticleType("News")}><FontAwesomeIcon icon={faNewspaper} fixedWidth /> News</a></ArticleMenuItem>
        <ArticleMenuItem articleType={articleType} select="Review"><a onClick={() => handleArticleType("Review")}><FontAwesomeIcon icon={faStarHalfAlt} fixedWidth /> Reviews</a></ArticleMenuItem>
        <ArticleMenuItem articleType={articleType} select="Genre"><a onClick={() => handleArticleType("Genre")}><FontAwesomeIcon icon={faGuitar} fixedWidth />Genres</a></ArticleMenuItem>
    </ArticleList>
  );
}

export default ArticleMenu;