import React from 'react';
import './youtubevideo.css';

function YoutubeVideo(props) {
  return (
    <div class="videoWrapper">
      <iframe title={props.videoId} width="560" height="315" src={"https://www.youtube.com/embed/" + props.videoId} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe>
    </div>
  );
}

export default YoutubeVideo;