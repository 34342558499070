import React from 'react';

const FormList = props => {
  return (
    <ul>
      {props.items.map(item => (
        <li key={JSON.stringify(item)} onClick={() => {props.removeItem(item)}}>{JSON.stringify(item)}</li>
      ))}
    </ul>
  )
}

export default FormList;