import React, { useState } from 'react';
import SiteNavigation from './sitenavigation';
import SiteSearch from './sitesearch';

const Header = ({props, onToggleDimmed}) => {
  const [searching, setSearching] = useState(false);

  const HandleSearchClick = () => {
    setSearching(true);
    onToggleDimmed();
  };
  
  const HandleCancelSearchClick = () => {
    setSearching(false);
    onToggleDimmed();
  };

  return (
    searching ? <SiteSearch handleCancelSearchClick={HandleCancelSearchClick} /> : <SiteNavigation handleSearchClick={HandleSearchClick} />
  );
}

export default Header;