import React from 'react';

var imgStyle = {
  objectFit: 'cover',
  objectPosition: 'top',
  height: 'calc(100vh - 56px)'
};

var textStyle = {
  textShadow: '0 1px 0 black'
}

const ArticleHeaderLargeImage = (props) => {
    return (
        <div id="article-header" className="card bg-dark text-white rounded-0">
            <img className="card-img" src={props.image} alt={props.title} style={imgStyle} />
            <div className="card-img-overlay d-flex flex-column justify-content-end">
                <h1 style={textStyle} className="card-title m-0 text-center">{props.title}</h1>
            </div>
        </div>
    );
}

ArticleHeaderLargeImage.defaultProps = {
    title: "",
    image: ""
}

export default ArticleHeaderLargeImage;