import React, { useState, useEffect, Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingSpinner from '../../components/loadingspinner';
import Container from 'react-bootstrap/Container';
import Media from 'react-bootstrap/Media';
import UsersGateway from '../../gateways/usersgateway';

function StaffList() {
  const [stafflist, setStaff] = useState({});
  const [loading, setLoading] = useState(true);
  const usersGateway = UsersGateway();

  useEffect(() => {
    const fetchStaff = async () => {
      const stafflist = await usersGateway.getStaff();
      stafflist.sort(function(a,b)
      {
        if(a.title > b.title) {return  1;}
        if(a.title < b.title) {return -1;}
        if(a.username > b.username ) {return  1;}
        if(a.username < b.username ) {return -1;}
        return 0;
      });

      setStaff(stafflist);
      setLoading(false);
    }

    fetchStaff();
  }, []);

  return (
    loading ? (
      <LoadingSpinner />
    ) : (
      <Fragment>
        <Container>
          <Row>
            <Col>
              <h3>Editorial Staff</h3>
              {stafflist.filter(staff => staff.area === "Editorial Staff").map(staff => (
                <Media key={staff.id}>
                  <div className="media-left mr-2">
                    <a href="/User/Alex-Rice">
                      <img 
                          width={64}
                          height={64}
                          className="mr-3"
                          alt={staff.username} 
                          src={staff.imageUrl} />
                    </a>
                  </div>
                  <Media.Body>
                    <h5 className="media-heading" id="media-heading">
                      {staff.username + '// ' + staff.title}
                    </h5>
                    {staff.biography}
                  </Media.Body>
                </Media>
              ))}

              <h3>Features and Reviews</h3>
              {stafflist.filter(staff => staff.area === "Features").map(staff => (
                <Media key={staff.id}>
                  <div className="media-left mr-2">
                    <a href="/User/Alex-Rice">
                      <img 
                          width={64}
                          height={64}
                          className="mr-3"
                          alt={staff.username} 
                          src={staff.imageUrl} />
                    </a>
                  </div>
                  <Media.Body>
                    <h5 className="media-heading" id="media-heading">
                      {staff.username + '// ' + staff.title}
                    </h5>
                    {staff.biography}
                  </Media.Body>
                </Media>
              ))}

              <h3>News</h3>
              {stafflist.filter(staff => staff.area === "News").map(staff => (
                <Media key={staff.id}>
                  <div className="media-left mr-2">
                    <a href="/User/Alex-Rice">
                      <img 
                          width={64}
                          height={64}
                          className="mr-3"
                          alt={staff.username} 
                          src={staff.imageUrl} />
                    </a>
                  </div>
                  <Media.Body>
                    <h5 className="media-heading" id="media-heading">
                      {staff.username + '// ' + staff.title}
                    </h5>
                    {staff.biography}
                  </Media.Body>
                </Media>
              ))}

              <h3>Technical Team</h3>
              {stafflist.filter(staff => staff.area === "Technical Team").map(staff => (
                <Media key={staff.id}>
                  <div className="media-left mr-2">
                    <a href="/User/Alex-Rice">
                      <img 
                          width={64}
                          height={64}
                          className="mr-3"
                          alt={staff.username} 
                          src={staff.imageUrl} />
                    </a>
                  </div>
                  <Media.Body>
                    <h5 className="media-heading" id="media-heading">
                      {staff.username + '// ' + staff.title + '// ' + staff.area}
                    </h5>
                    {staff.biography}
                  </Media.Body>
                </Media>
              ))}
              
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  );
}

export default StaffList;