export function chunkarray(arr, size) {
  if (!Array.isArray(arr)) {
    throw new TypeError('Input should be Array');
  }

  if (typeof size !== 'number') {
    throw new TypeError('Size should be a Number');
  }

  var result = [];
  for (var i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, size + i));
  }

  return result;
};

export function truncateText(str, length) {
  return str.length > 10 ? str.substring(0, length) + " ..." : str;
}

export function computeDollars(btc, btcRate) {
  return btc * btcRate;
}

export function formatDollars(dollars) {
  dollars = (Math.round(dollars * 100) / 100).toFixed(2);
  dollars = dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return dollars;
}