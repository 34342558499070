import React, { useState, Fragment } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useAuth } from '../../hooks/useAuth';

function Signin() {
    const auth = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        auth.signin(email, password);

        // ToDo Redirect or something?
    }

  return (
    <Fragment>
        <h3>Log in to an existing account</h3>
        <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control name="email" type="email" placeholder="Enter email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" onChange={e => setEmail(e.target.value)} />
                <Form.Control.Feedback type="invalid">
                Please provide a valid email.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control name="password" type="password" placeholder="Password" required onChange={e => setPassword(e.target.value)} />
                <Form.Control.Feedback type="invalid">
                Please provide a valid password.
                </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">
                Log in
            </Button>
        </Form>
    </Fragment> 
  );
}

export default Signin;