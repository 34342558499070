import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from "react-router-dom";

const navbarStyle = {
    padding: '0px',
    height: '56px',
}

const searchBoxStyle = {
    background: '#2b2d31',
    fontSize: '20px',
    color: '#ffffff',
    outline: 'none',
    height: '56px'
};

function SiteNavigation(props) {
    let history = useHistory();
    const [searchHistory, setSearchHistory] = useState('');

    const search = (pressedKey, searchTerm) => {
        console.log('In Search');

        if (pressedKey === 'Enter') {
            console.log(searchTerm);
            history.push(`/search/${searchTerm}`);
        }
    }    

    return (
        <Navbar bg="dark" variant="dark" expand="md" style={navbarStyle}>
            <Container>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Form inline style={searchBoxStyle}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <Button variant="link" onClick={props.handleSearchClick}>
                                    <FontAwesomeIcon icon={faSearch} color="white" className="search-button" />
                                </Button>
                            </InputGroup.Prepend>
                            <FormControl plaintext style={searchBoxStyle} type="text" placeholder="Search" className="mr-sm-2" onChange={event => {setSearchHistory({query: event.target.value})}} onKeyPress={event => { search(event.key, event.target.value)}} />
                        </InputGroup>
                    </Form>
                    <Button variant="link" onClick={props.handleCancelSearchClick}>
                        <FontAwesomeIcon icon={faTimes} color="white" className="search-button" />
                    </Button>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default SiteNavigation;