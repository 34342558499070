import React from 'react';
import { default as BootstrapButton } from 'react-bootstrap/Button';
import Styled from 'styled-components';

const StyledButton = Styled(BootstrapButton)`
    color: #ffffff;
    background-color: #FF5A00;
    border-color: #FF5A00;
    border-radius: 0;
    margin-top: 15px;
    margin-bottom: 15px;
`;

function Button(props) {
    return (
        <StyledButton href={props.href} variant="primary" type={props.type} disabled={props.disabled}>{props.text}</StyledButton>
    )
}

export default Button;