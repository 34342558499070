import React from 'react';
import Card from 'react-bootstrap/Card';
import BasicScore from '../basicscore';
import Styled from 'styled-components';

const BootstrapCardImageOverlay = Styled(Card.ImgOverlay)`
  padding: .5rem
`;

const BootstrapCard = Styled(Card)`
  color: #000000;
  border-style: none;
  background-color: transparent;
`;

const BootstrapCardBody = Styled(Card.Body)`
  padding-top: 5px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
`;

const BootstrapCardImage = Styled(Card.Img)`
  outline: 1px solid rgba(0, 0, 0, 0.1);
  outline-offset: -1px;  

  ${BootstrapCard}:hover & {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    transition: all 1s ease;
    -webkit-transition: all 1s ease;
  }
`;

const BootstrapCardTitle = Styled.div`
  font-family: Oswald,Arial,sans-serif;
  color: #000000;
  ${BootstrapCard}:hover & {
    color: #FF5A00;
  }
`;

const ReviewSummary = (props) => {
  return (
    <a href={"/reviews/" + props.id}>
      <BootstrapCard className="rounded-0 border-0">
          <BootstrapCardImage variant="top" src={props.image} className="h-100" alt={props.title} />
          <BootstrapCardImageOverlay>
            <BasicScore score={props.score} />
          </BootstrapCardImageOverlay>
          <BootstrapCardBody>
              <BootstrapCardTitle className="card-title">{props.title}</BootstrapCardTitle>
          </BootstrapCardBody>
      </BootstrapCard>
	  </a>
  );
}

ReviewSummary.defaultProps = {
  image: '',
  title: '',
  score: 5
};

export default ReviewSummary;