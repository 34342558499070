import React, { useState, useEffect } from 'react';
import Media from 'react-bootstrap/Media'
import PostGateway from '../../gateways/postsGateway';
import Post from '../../components/post';

function Board() {
  const [posts, setPosts] = useState([]);
  const gateway = PostGateway();

  useEffect(() => {
    const fetchPosts = async () => {
      const latestPosts = await gateway.getAll();
      setPosts(latestPosts);
    }

    fetchPosts();
  }, []);

  return (
    <div>
      {posts.map(post => (
        <Post authorId={post.authorId} authorName={post.authorName} authorImage={post.authorImage} content={post.conent} postId = {post.id} createdDate={post.createdDate} staff={post.staff} moderation={post.moderator} />
      ))}
    </div>
  );
}

export default Board;