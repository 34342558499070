import React, { useState, useEffect, Fragment } from 'react';
import ArticleGateway from '../../gateways/articlesgateway';
import LoadingSpinner from '../loadingspinner';
import Article from './article';

const LatestArticles = ({articleType}) => {

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const articleGateway = ArticleGateway();

  useEffect(() => {
    const fetchArticles = async () => {
      const latestArticles = await articleGateway.getAll(articleType);
      setArticles(latestArticles);
      setLoading(false);
    }
    fetchArticles();
  }, [articleType])

  return (
    <Fragment>
      {loading ? (
        <LoadingSpinner />
      ) : (
        articles.item2.map(article => (
          <Article 
            key={article.id}
            title={article.title}
            type={article.type}
            url={article.image}
            createdDate={article.createdDate}
            id={article.id}
            content={article.content}
          />
        ))
      )}
    </Fragment>
  )
}

export default LatestArticles;