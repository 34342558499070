import React, { useState, Fragment } from 'react';
import SimpleMDE from "react-simplemde-editor";
import MediaLibrary from '../../components/medialibrary';
import "easymde/dist/easymde.min.css";

const MarkDownEditor = (props) => {   
  const [editor, setEditor] = useState();
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);

  const handleInlineImage = (content) => {
    var cm = editor.codemirror;
    cm.replaceSelection(content);
  }

  const fullToolbar = [
    'redo',
    'undo',
    "|", 
    'heading-bigger', 
    'heading-smaller',
    "italic",
    "bold",
    "strikethrough",
    "|",
    "quote",
    "unordered-list",
    "ordered-list",
    "|",
    "link",
    {
      name: "medialibrary",
      action: function openMediaLibrary(editor){
        // Set Editor so we can reference it later.
        setEditor(editor);
        setShowMediaLibrary(true);
      },
      className: "fa fa-image",
      title: "Media Library"
    },
    "table",
    "|",
    "guide",
    {
      name: "youtube",
      action: function createYoutubeLink(editor){
        var cm = editor.codemirror;
        var output = '';
        var selectedText = cm.getSelection();
        var text = selectedText || 'placeholder';
        output = '![youtube video](http://www.youtube.com/watch?v=' + text + ')';
        cm.replaceSelection(output);
      },
      className: "fa fa-youtube",
      title: "Youtube Link",
    }
  ];
  
  const simpleToolbar = [
    'redo',
    'undo',
    "|", 
    "italic",
    "bold",
    "strikethrough",
    "|",
    "unordered-list",
    "ordered-list",
    "|",
    "link",
    {
        name: "youtube",
        action: function createYoutubeLink(editor){
            let content = editor.value();
            editor.value(content + "![youtube video](http://www.youtube.com/watch?v=dQw4w9WgXcQ =800x600)");
        },
        className: "fa fa-youtube",
        title: "Youtube Link",
    },
    "|",
    "guide",
  ];

  // Build SimpleMDE
  var mde;
  if (props.mode === 'basic') {
    mde = <SimpleMDE onChange={props.onChange} options={{ 
      toolbar: false, 
      minHeight: 50, 
      status: false, 
      placeholder: props.placeholder }} />
  } else if (props.mode === 'simple') {
    mde = <SimpleMDE onChange={props.onChange} options={{ 
      toolbar: simpleToolbar, 
      placeholder: props.placeholder }} />
  } else {
    mde = <SimpleMDE onChange={props.onChange} options={{ 
      toolbar: fullToolbar, 
      placeholder: props.placeholder }} />
  }

  return (
    <Fragment>
      {mde}
      <MediaLibrary imagePrefix={props.imagePrefix} show={showMediaLibrary} setShow={setShowMediaLibrary} addImage={handleInlineImage} />
    </Fragment>
  );
}

MarkDownEditor.defaultProps = {
  mode: 'full',
  imagePrefix: '',
  placeholder: '',
  onChange: null
}

export default MarkDownEditor;