import React, { useState, useEffect, Fragment } from 'react';
import ArticleBody from '../../components/articlebody';
import ReviewGateway from '../../gateways/reviewsGateway';
import LoadingSpinner from '../../components/loadingspinner';
import ArticleHeaderText from '../../components/articleheadertext';
import ArticleAuthor from '../../components/articleauthor';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Byline from '../../components/byline';
import Comments from '../../components/comments';
import Image from '../../components/image';
import AlbumTracks from '../../components/albumtracks';

function Review(props) {
  const [review, setReview] = useState({});
  const [loading, setLoading] = useState(true);
  const reviewGateway = ReviewGateway();

  useEffect(() => {
    const fetchReview = async () => {
      const retrievedReview = await reviewGateway.get(props.match.params.id); 
      setReview(retrievedReview);
      setLoading(false);
    }
    fetchReview();
  }, []);

  return (
    loading ? (
      <Container>
        <LoadingSpinner />
      </Container>
    ) : (
      <Fragment>
        <ArticleHeaderText header={review.title} subheader={review.summary} />
        <Byline authorId={review.author.id} authorName={review.author.username} articleType="Review" articleDate={review.createdDate} />
        <Container>
          <Row>
            <Col md={{ span: 9, order: 'last' }}>
              <ArticleBody content={review.content} />
            </Col>
            <Col md={{ span: 3, order: 'first' }}>
              <Image url={review.album.imageUrl} />
              <AlbumTracks tracks={review.album.tracks} />
            </Col>
          </Row>
        </Container>
        
        <ArticleAuthor id={review.author.id} username={review.author.username} biography={review.author.biography} image={review.author.imageUrl} biography={review.author.biography} />
        <Comments articleId={props.match.params.id} />
      </Fragment>
    )      
  );
}

export default Review;