import React, { useState, useEffect } from 'react';
import PostsGateway from '../../gateways/postsGateway';
import MarkDownEditor from '../markdowneditor';
import Form from 'react-bootstrap/Form';
import Button from '../../components/button';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../../hooks/useAuth';

function CreatePost(props) {
  const [id, setId] = useState(uuidv4());
  const [content, setContent] = useState("");
  const boardId = props.boardId;
  const postsGateway = PostsGateway();

  const auth = useAuth();
  const user = auth.getUser();

  const handleSubmit = async (event) => {
    event.preventDefault();

    var formData = new FormData();
    formData.append("id", id);
    formData.append("title", "");
    formData.append("content", content);

    // Post Form
    const result = await postsGateway.post(boardId, formData);

    // Refresh Page
    props.fetchPosts();
  }

  if(user) {
    return (
      <React.Fragment>
        <h1>Create a Post</h1>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formContent">
            <MarkDownEditor onChange={content => setContent(content)} placeholder={'Content'} />
            <Button variant="primary" type={"submit"} text={"Post"} />
          </Form.Group>
        </Form>
      </React.Fragment>
    );
  }
}

export default CreatePost;