import React, { useState, useEffect, Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingSpinner from '../loadingspinner';
import ReviewSummary from '../reviewsummary';
import ReviewsGateway from '../../gateways/reviewsGateway';

function LatestReviews() {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const reviewsGateway = ReviewsGateway();

  useEffect(() => {
    const fetchReviews = async () => {
      const latestReviews = await reviewsGateway.getAll();
      setReviews(latestReviews);
      setLoading(false);
    }
    fetchReviews();
  }, []);

  return (
    <Container className="pt-4">
      <Row>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Fragment>
            <Col md={3} sm={6} xs={6}>
              <ReviewSummary id={reviews[0].id} image={reviews[0].album.imageUrl} title={reviews[0].title} score={reviews[0].score.name} />
            </Col>
            <Col md={3} sm={6} xs={6}>
              <ReviewSummary id={reviews[1].id} image={reviews[1].album.imageUrl} title={reviews[1].title} score={reviews[1].score.name} />
            </Col>
            <Col md={3} sm={6} xs={6}>
              <ReviewSummary id={reviews[2].id} image={reviews[2].album.imageUrl} title={reviews[2].title} score={reviews[2].score.name} />
            </Col>
            <Col md={3} sm={6} xs={6}>
              <ReviewSummary id={reviews[3].id} image={reviews[3].album.imageUrl} title={reviews[3].title} score={reviews[3].score.name} />
            </Col>
          </Fragment>
        )}
      </Row>
    </Container>
  );
}

export default LatestReviews;