import React, { useState } from 'react';
import BlogGateway from '../../gateways/blogGateway';
import HtmlToMarkdown from '../../helpers/HtmlToMarkdown';
import Container from 'react-bootstrap/Container';
import { Editor } from '@tinymce/tinymce-react';

function CreateBlogPost() {
    const [title, setTitle] = useState("Title your Blog Post");
    const [content, setContent] = useState("");
    const blogGateway = BlogGateway();

    const handleSubmit = async (event) => {
        event.preventDefault();

        var markdown = HtmlToMarkdown(content);

        var formData = new FormData();
        formData.append("title", title);
        formData.append("content", markdown);

        await blogGateway.post(formData);
    }

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <input type="text" name="title" onChange={e => setTitle(e.target.value)} value={title} />
        <Editor
          apiKey="vvy1r3ob4ptjo7ze0np5hynt80m3ejlcn9okylsii2l0tw9e"
          init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help'
          }}
          value={content} onEditorChange={content => setContent(content)}
        />
        <button type="submit">Publish</button>
      </form>
    </Container>
  )
}

export default CreateBlogPost;