import React from 'react';
import Styled from 'styled-components';

const StyledImage = Styled.img`
  outline: 1px solid rgba(0, 0, 0, 0.1);
  outline-offset: -1px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 100%;
`;

const Image = (props) => {
  return (
    <StyledImage src={props.url} />
  )
}

Image.defaultProps = {
  url: ""
};

export default Image;