import { articleListUrl, userArticleListUrl } from './urls';

const ArticlesGateway = () => {

  async function getAll(type) {
    return await fetch(articleListUrl(type))
      .then(toJson);
  }

  async function getByUser(userId) {
    return await fetch(userArticleListUrl(userId))
      .then(toJson);
  }

  function toJson(response){
    return response.json();
  }
  
  return Object.freeze({
      getAll,
      getByUser
  });
}

export default ArticlesGateway;