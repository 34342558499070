import { featureListUrl, featureUrl } from './urls';
import { useAuth } from '../hooks/useAuth';

export default function FeaturesGateway(){
  const auth = useAuth();
  const jwt = auth.jwt;

  async function getAll() {
    return await fetch(featureListUrl)
      .then(toJson);
  }

  async function post(formdata) {
    var requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Authorization':  `bearer ${jwt}`
      }), 
      body: formdata,
      redirect: 'follow'
    };

    return await fetch(featureListUrl, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  async function get(id) {
    return await fetch(featureUrl(id))
      .then(toJson);
  }
  
  function toJson(response){
    return response.json();
  }
  
  return  Object.freeze({
      getAll,
      get,
      post
  });
}